import React from "react"
import styled from "styled-components"
import Wave from "../images/doodad_wave.svg"

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #2e3440;
`
const ContainerLinux = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #212223;
`

const TextHero = styled.div`
  font-size: 5rem;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  color: #fff;
  position: relative;
  z-index: 99;
  &::before {
    content: "";
    position: absolute;
    background-image: url(${Wave});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 400px;
    height: 88px;
    top: 24px;
    left: 120px;
    z-index: -1;
  }
`

export default function TechnologiesHero() {
  return (
    <div>
      <Container>
        <TextHero>Technologies</TextHero>
      </Container>
      <ContainerLinux>
        <TextHero>Technologies</TextHero>
      </ContainerLinux>
    </div>
  )
}
