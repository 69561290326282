import React from 'react'
import TechnologiesHero from '../components/TechnologiesHero'
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,300;1,400&display=swap');
  body {
    margin: 0;
  }`;

export default function technologies() {
  return (
    <div>
        <GlobalStyle/>
        <TechnologiesHero />
    </div>
  )
}
